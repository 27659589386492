import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import chimeraLogoBig from "../images/chimLogoBig.svg";

const Footer = () => {
    function handleScrollToElement(event, elementId) {
        event.preventDefault();
        const element = document.getElementById(elementId);

        if (element) {
            const top =
                element.getBoundingClientRect().top + window.pageYOffset - 100; // -100 offset for the header or desired space from top
            window.scrollTo({ top: top, behavior: "smooth" });
        }
    }
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
                        {/* 1st block */}
                        <div className="col-span-12 lg:col-span-4">
                            <div className="box-border border-b-4 border-white p-8 bg-white text-white text-center rounded-lg xl:w-80 mx-auto">
                                <img
                                    alt="Chimera Solutions"
                                    src={chimeraLogoBig}
                                ></img>
                            </div>
                        </div>

                        {/* 2nd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                            <h6 className="text-black text-xl font-bold mb-4">
                                LINKS
                            </h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <HashLink
                                        to="/#about"
                                        className="text-black  hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                                    >
                                        About
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        to="/services"
                                        className="text-black  hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                                    >
                                        Services
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        to="/contact"
                                        className="text-black  hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                                    >
                                        Contact
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        to="/products"
                                        className="text-black  hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                                    >
                                        Products
                                    </HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink
                                        to="/team"
                                        className="text-black  hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                                    >
                                        Team
                                    </HashLink>
                                </li>
                            </ul>
                        </div>

                        {/* 3rd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                            <h6 className="text-black  text-xl font-bold mb-4">
                                OUR SERVICES
                            </h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <Link
                                        to="/services#MachineLeaningService"
                                        className="text-black  hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                                    >
                                        Machine Learning
                                    </Link>
                                </li>
                                <li className="mb-2">
                                    <Link
                                        to="/services#ComputerVisionService"
                                        className="text-black  hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                                    >
                                        Computer Vision
                                    </Link>
                                </li>
                                <li className="mb-2 text-black  hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                    <Link
                                        to="/services#SWADService"
                                    >
                                        Software <br />
                                        Architectural <br />
                                        Desing
                                    </Link>
                                </li>
                                <li className="mb-2 text-black  hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                    <Link
                                        to="/services#ConsultancyandOptimizationService"
                                    >
                                        Consultancy and <br /> Optimization
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        {/* 4th block */}
                        <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-black-900">
                            <div className="text-xl mb-6">
                                Social Media Links.
                            </div>

                            <div className="text-md font-medium mb-6">
                                Follow us on social media.
                            </div>
                            <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                        <Link
                                            to="https://twitter.com/chimeramultimed"
                                            className="flex justify-center items-center text-black-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                            aria-label="Twitter"
                                        >
                                            <svg
                                                className="w-8 h-8 fill-current"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="ml-4">
                                        <Link
                                            to="https://www.linkedin.com/company/chimera-multimedia-and-solutions"
                                            className="flex justify-center items-center text-black-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                            aria-label="LinkedIn"
                                        >
                                            <svg
                                                className="w-8 h-8 fill-current"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g transform="scale(0.06) translate(8,8)">
                                                    <path d="M186.4 142.4c0 19-15.3 34.5-34.2 34.5 -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1 107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8 201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0 31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z" />
                                                </g>
                                            </svg>
                                        </Link>
                                    </li>

                                    <li className="ml-4">
                                        <Link
                                            to="https://www.youtube.com/channel/UCdxmsmwcALg7nkWZ-GV8zbA"
                                            className="flex justify-center items-center text-black-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                            aria-label="Youtube"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="h-8 w-8"
                                                fill="currentColor"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                                            </svg>
                                        </Link>
                                    </li>

                                    <li className="ml-4">
                                        <Link
                                            to="https://discord.gg/qD6Xbvy27h"
                                            className="flex justify-center items-center text-black-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                                            aria-label="Discord"
                                        >
                                            <svg
                                                class="h-8 w-8"
                                                fill="currentColor"
                                                viewbox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g transform="scale(1.2) translate(2,2)">
                                                    <path d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z" />
                                                </g>
                                            </svg>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-200 font-semibold py-1">
                                Copyright &copy; {new Date().getFullYear()}
                                {"  "}
                                <HashLink
                                    to="#"
                                    className=" hover:text-gray-900"
                                >
                                    Chimera Solutions KFT
                                </HashLink>
                                . All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
