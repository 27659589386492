import React from 'react';
import { teamdata } from '../constant/teamdata'; // Adjust the path if necessary
import cto from "../images/cto.png";
import ceo from "../images/ceo.png";
import manager from "../images/manager.png";
import adam from "../images/adam.png";
import NavBar from '../components/Navbar/NavBar';

const avatars = [ceo, cto, adam, manager]

function Team() {
  return (
    <>
        <div>
            <NavBar />
        </div>
        <div className=' py-12'>

        </div>
        <div className="bg-gray-300 w-full min-h-screen p-5">
        <div className="max-w-screen-xl mx-auto">
            <div className="font-neuebold">
            <span className="text-9xl font-neuebold text-center text-black md:text-9xl sm:text-9xl xs:text-9xl">Team</span>
            <div className="flex flex-col mt-5">
                <span className="text-4xl font-neuebold leading-none text-black md:text-3xl sm:text-2xl xs:text-xl">Innovation</span>
                <span className="text-4xl font-neuebold leading-none text-black md:text-3xl sm:text-2xl xs:text-xl">Consistency</span>
                <span className="text-4xl font-neuebold leading-none text-black md:text-3xl sm:text-2xl xs:text-xl">Quality</span>
            </div>
            <hr className="border-black border-t-4 w-full my-8" />
            <div className="flex flex-col font-neueregular">
                <span className="text-lg font-neuebold text-black xs:text-sm ">OUR TEAM IS</span>
                <span className="text-xl font-neueregular text-black sm:text-lg xs:text-base leading-relaxed">
                Highly motivated to produce high-quality products, solve real-life problems, and use state-of-the-art technology. We believe in good communication and precise planning to deliver the most convenient services and solutions to our customers. We are looking forward to working with you!
                </span>
            </div>
            </div>

            {/* Team Members */}
            {teamdata.map((member, idx) => (
            <div id={idx+"_team_id"} key={idx} className="mt-20 md:mt-10">
                {/* Team Avatar */}
                <div className="flex flex-col sm:w-60 w-full sm:h-75">
                    <img src={avatars[idx]} alt="avatar" width={578} height={578} className="w-full h-auto" />
                </div>
                <div className="max-w-screen-xl mx-auto">
                <hr className="border-black border-t-4 w-full my-2" />
                <div className="font-neuethin ">{member.role}</div>
                <div className="text-4xl leading-none text-black md:text-3xl sm:text-2xl xs:text-xl font-neuebold my-4">{member.name}</div>
   
                <div className="text-lg font-neueregular text-black xs:text-sm">CONTACT</div>
                <div className="text-xl font-neuebold text-black sm:text-lg xs:text-base leading-relaxed">{member.contact_email}</div>
                <div className="text-xl font-neuebold text-black sm:text-lg xs:text-base leading-relaxed">{member.phone}</div>

                <div className="w-full flex flex-col mt-4 ">
                    <span className="text-lg text-black xs:text-sm font-neuebold">LET ME INTRODUCE MYSELF</span>
                    <hr className="border-black border-t-2 w-full my-4" />
                    <div className="text-xl font-neuebold text-black sm:text-lg xs:text-base leading-relaxed font-neueregular">{member.intro}</div>
                </div>
                </div>
                
                {/* Skills, Links and Hobbies */}
                <div className="flex flex-wrap justify-between mt-8">
                {/* Links */}
                <div className="w-1/2 flex flex-col mt-8 ">
                    <span className="text-lg font-neueregular text-black sm:text-sm xs:text-sm font-neuebold">LINKS</span>
                    <hr className="border-black border-t-4 w-full my-4" />
                    {member.link && member.link.map((item, key) => (
                    <a key={key} href={item.link} target="_blank" rel="noopener noreferrer" className="text-5xl font-neuebold leading-none text-black hover:bg-gray-200 hover:text-gray-500 md:text-3xl sm:text-2xl xs:text-lg font-neuebold">
                        {item.title}
                    </a>
                    ))}
                </div>

                {/* Hobbies */}
                <div className="w-1/2 flex flex-col mt-8 ">
                    <span className="text-lg font-neueregular text-black xs:text-sm font-neuebold">FREETIME</span>
                    <hr className="border-black border-t-4 w-full my-4" />
                    {member.hobby && member.hobby.map((hobby, key) => (
                    <span key={key} className="text-4xl font-neuebold leading-none text-black md:text-3xl sm:text-2xl xs:text-xl font-neuebold">
                        {hobby}
                    </span>
                    ))}
                </div>
                </div>

                {/* More Skills */}
                <div className="flex flex-wrap mt-8">
                {/* Programming Languages */}
                <div className="w-1/2 flex flex-col mt-8 ">
                    <span className="text-lg font-neueregular text-black xs:text-sm font-neuebold">LANGUAGES</span>
                    <hr className="border-black border-t-4 w-full my-4" />
                    {member.language && member.language.map((lang, key) => (
                    <span key={key} className="text-3xl font-neuebold leading-none text-black sm:text-2xl xs:text-xl font-neuebold">
                        {lang}
                    </span>
                    ))}
                </div>

                {/* Frameworks */}
                <div className="w-1/2 flex flex-col mt-8 ">
                    <span className="text-lg font-neueregular text-black xs:text-sm font-neuebold">FRAMEWORKS</span>
                    <hr className="border-black border-t-4 w-full my-4" />
                    {member.frame && member.frame.map((frame, key) => (
                    <span key={key} className="text-3xl font-neuebold leading-none text-black sm:text-2xl xs:text-xl font-neuebold">
                        {frame}
                    </span>
                    ))}
                </div>
                
                {/* Software */}
                <div className="w-1/2 flex flex-col mt-8 ">
                    <span className="text-lg font-neueregular text-black xs:text-sm font-neuebold">SOFTWARE</span>
                    <hr className="border-black border-t-4 w-full my-4" />
                    {member.software && member.software.map((soft, key) => (
                    <span key={key} className="text-3xl font-neuebold leading-none text-black sm:text-2xl xs:text-xl font-neuebold">
                        {soft}
                    </span>
                    ))}
                </div>

                {/* Soft Skills */}
                <div className="w-1/2 flex flex-col mt-8 ">
                    <span className="text-lg font-neueregular text-black xs:text-sm">SOFT SKILLS</span>
                    <hr className="border-black border-t-4 w-full my-4" />
                    {member.softskill && member.softskill.map((skill, key) => (
                    <span key={key} className="text-3xl font-neuebold leading-none text-black sm:text-2xl xs:text-xl font-neuebold">
                        {skill}
                    </span>
                    ))}
                </div>
                </div>
            </div>
            ))}
        </div>
        </div>
    </>
  );
}

export default Team;
