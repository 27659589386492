import React, { useRef,useState, useEffect} from 'react';
import ceo from '../images/ceo.png';
import cto from '../images/cto.png';
import manager from '../images/manager.png';
import adam from '../images/adam.png';
import {Link, useNavigate, useLocation} from 'react-router-dom';

const Team = () => {
    const scrollContainer = useRef(null);

    const scroll = (scrollOffset) => {
        scrollContainer.current.scrollLeft += scrollOffset;
    };

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (anchorId) => {
        navigate(`/team#${anchorId}`);
        setTimeout(() => {
            const element = document.getElementById(anchorId);
            if (element) {
                const offsetTop = element.getBoundingClientRect().top + window.scrollY - 120 ;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            }
        }, 300); // A delay to ensure the new content is rendered
    };

    const renderTeamMembers = () => (
        <>
            <TeamMemberCard
                id="0_team_id"
                image={ceo}
                name="Demeter Márton Csaba"
                title="CEO"
                description="P.hD. candidate in Biophysics, 4+ years experience in ML and Data Science, 4+ years experience in entrepreneurship, 7+ years experience in academic research"
                onClick={handleClick} 
            />
            <TeamMemberCard
                id="1_team_id"
                image={cto}
                name="Török Gábor"
                title="COO"
                description="Computer Science MSc, 6+ years experience in Software Architectual Design, 4+ years experience in C++"
                onClick={handleClick} 
            />
            <TeamMemberCard
                id="2_team_id"
                image={adam}
                name="Tran Ádám"
                title="Software Engineer"
                description="Computer Science MSc student, 3+ years experience in DL/ML, 3+ years experience in Data Science"
                onClick={handleClick} 
            />
            <TeamMemberCard
                id="3_team_id"
                image={manager}
                name="Németh Barnabás"
                title="Social Media Manager"
                description="TCRT - Community Management Certificate, HubSpot Academy - Digital Advertising, Google Digital Marketing & E-commerce"
                onClick={handleClick} 
            />
        </>
    );

    return (
        <div id="teamcomp" className="bg-gray-100 py-12">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-black uppercase font-neuebold">team</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-black'></div>
                    </div>
                </div>

                <div className="md:flex hidden justify-between items-center">
                    <button onClick={() => scroll(-200)} className="focus:outline-none">
                        <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                    </button>

                    <div ref={scrollContainer} className="flex overflow-x-scroll scroll-smooth py-2">
                        <div className="flex space-x-5">
                            {renderTeamMembers()}
                        </div>
                    </div>

                    <button onClick={() => scroll(200)} className="focus:outline-none">
                        <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </button>
                </div>

                <div className="md:hidden">
                    {renderTeamMembers()}
                </div>
            </section>
        </div>
    );
};

const TeamMemberCard = ({ id, image, name, title, description, onClick }) => (
    <div onClick={() => onClick(id)} className="cursor-pointer">
        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-black-700 hover:bg-black hover:text-white rounded-lg shadow-2xl p-3 h-full flex flex-col justify-between">
            <div className="m-2 text-justify text-sm ">
                <img alt={`Profile of ${name}`} className="rounded-t block mx-auto group-hover:scale-[1.05] transition duration-1000 ease-in-out" src={image} />
                <div className="font-neuebold">
                    <h2 className="font-semibold my-4 text-2xl text-center">{name} <br /> {title}</h2>
                </div>
                <p className="font-neueregular">
                    {description.split(', ').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}<br />
                        </React.Fragment>
                    ))}
                </p>
            </div>
        </div>
    </div>
);

export default Team;
