import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Team from '../components/Team';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import MainBG from '../components/MainBG';
import Community from '../components/Community';
import SocialMediaPosts from '../components/Socials';


const Home = () => {
    return (
        <>
            <MainBG />
            <Hero />
            <Team />
            <Services />
            <Community/>
            <Cta/>
            <Footer />
        </>

    )
}

export default Home;

