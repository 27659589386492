import React from "react";
import { Link } from "react-router-dom";


const Community = () => {
    return (
        <>
            <div className="bg-[url(./images/hero-bg.svg)] bg-no-repeat bg-cover bg-bottom lg:bg-center" id="hero">
                <div
                    className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
                    data-aos="zoom-in"
                >
                    <div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div
                            className="lg:w+1/2 flex flex-col justify-center"
                            data-aos="zoom-in"
                            data-aos-delay="200"
                        >
                            <div className="font-neuebold">
                                <h1 className="mb-5 md:text-5xl text-3xl font-bold">
                                    Join the community and
                                    <br /> explore the state-of-the-art
                                    together.
                                </h1>
                            </div>
                            <div className="font-neueregular">
                                <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                                    We think communication is key, thus we share
                                    our thoughts and projects to the world
                                    consistently. <br /> We are interested in
                                    your opinion and feedback.
                                    <br /> Share your thoughts on our LinkedIn
                                    and Twitter and join our Discord today.
                                </div>
                            </div>
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                <Link to="/contact" className="linkbutton">
                                    Learn more
                                    <svg
                                        className="w-4 h-4 ml-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Community;
