import React from 'react';

import MachineLearningIcon from '../images/mleaning.svg';
import CompVisionIcon from '../images/compvision.svg';
import SWADesign from '../images/swarchdesgin.svg';
import Consultancy from '../images/consultancy.svg';
import ServicesImg from '../images/services.svg';
import swissarrowImg from '../images/swissarrow.svg';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';


const Services = () => {
    function handleScrollToElement(event, elementId) {
        event.preventDefault();
        const element = document.getElementById(elementId);

        if (element) {
            const top =
                element.getBoundingClientRect().top + window.scrollY - 100; // -100 offset for the header or desired space from top
            window.scrollTo({ top: top, behavior: "smooth" });
        }
    }

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="bg-gray-100 min-h-screen">
                <section id="services_intro">
                    <div
                        className="m-auto overflow-hidden  py-10  mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
                        data-aos="zoom-in"
                    >
                        <div className="flex flex-col lg:flex-row mt-16 justify-between text-center lg:text-left">
                            <div
                                className="lg:w-1/2 flex flex-col justify-center"
                                data-aos="zoom-in"
                                data-aos-delay="200"
                            >
                                <div className="font-neuebold">
                                    <h2 className="  mb-5 md:text-9xl xs:text-5xl  text-9xl font-bold">
                                        {/* We build digital solutions to help businesses scale */}
                                        Services
                                    </h2>
                                    <h1 className="  py-0 mb-5 md:text-3xl text-3xl font-bold">
                                        Fitted to your needs
                                    </h1>
                                </div>
                                <div className="font-neueregular">
                                    <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                                        Understanding the unique challenges and
                                        requirements of each client, our
                                        services are tailored to provide the
                                        most effective solutions. <br /> Our
                                        team is highly motivated to produce
                                        high-quality products, solve real-life
                                        problems, and use state-of-the-art
                                        technology. We believe in good
                                        communication and precise planning to
                                        deliver the most convenient services and
                                        solutions to our customers. We are
                                        looking forward to working with you! .
                                    </div>
                                </div>

                                <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                    <Link to="/contact" className="linkbutton">
                                        Contact Us
                                        <svg
                                            className="w-4 h-4 ml-1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </Link>
                                    {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Get Started
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link> */}
                                    {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Learn More
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                                </a> */}
                                </div>
                            </div>
                            <div
                                className="flex lg:justify-end w-full lg:w-1/3"
                                data-aos="fade-up"
                                data-aos-delay="700"
                            >
                                <img
                                    alt="card img"
                                    className="rounded-t float-right duration-1000 w-full"
                                    src={ServicesImg}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section data-aos="zoom-in-down" className="py-12">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-black uppercase font-neuebold">
                            We offer
                        </h2>

                        <div className="flex justify-center">
                            <div className="w-24 border-b-4 border-black"></div>
                        </div>
                    </div>

                    <div
                        className="px-12 "
                        data-aos="fade-down"
                        data-aos-delay="600"
                    >
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            <div className="flex h-[10%]">
                                <button
                                    onClick={(e) =>
                                        handleScrollToElement(
                                            e,
                                            "MachineLearningService"
                                        )
                                    }
                                >
                                    <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-black-700 hover:bg-black hover:text-white rounded-lg shadow-2xl p-3 group">
                                        <div className="m-2 text-justify text-sm">
                                            <img
                                                alt="card img"
                                                className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out group-hover:invert"
                                                src={MachineLearningIcon}
                                            />
                                            <div className="font-neuebold">
                                                <h2 className="font-semibold my-4 text-2xl xs:text-xl sm:text-xl md:text-md lg:text-lg xl:text-2xl text-center truncate-2-lines">
                                                    Machine <br /> Learning
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="flex h-[10%]">
                                <button
                                    onClick={(e) =>
                                        handleScrollToElement(
                                            e,
                                            "ComputerVisionService"
                                        )
                                    }
                                >
                                    <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-black-700 hover:bg-black hover:text-white rounded-lg shadow-2xl p-3 group">
                                        <div className="m-2 text-justify text-sm">
                                            <img
                                                alt="card img"
                                                className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out group-hover:invert"
                                                src={CompVisionIcon}
                                            />
                                            <div className="font-neuebold">
                                                <h2 className="font-semibold my-4 text-2xl xs:text-xl sm:text-xl md:text-md lg:text-lg xl:text-2xl text-center truncate-2-lines">
                                                    Computer <br /> Vision
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="flex h-[10%]">
                                <button
                                    onClick={(e) =>
                                        handleScrollToElement(e, "SWADService")
                                    }
                                    className="w-full"
                                >
                                    <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-black-700 hover:bg-black hover:text-white rounded-lg shadow-2xl p-3 group">
                                        <div className="m-2 text-justify text-sm">
                                            <img
                                                alt="card img"
                                                className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out group-hover:invert"
                                                src={SWADesign}
                                            />
                                            <div className="font-neuebold">
                                                <h2 className="font-semibold my-4 text-2xl xs:text-xl sm:text-xl md:text-md lg:text-lg xl:text-2xl text-center truncate-2-lines ">
                                                    Software Architectural <br />{" "}
                                                    Design
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className="flex h-[10%]">
                                <button
                                    onClick={(e) =>
                                        handleScrollToElement(
                                            e,
                                            "ConsultancyandOptimizationService"
                                        )
                                    }
                                >
                                    <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-black-700 hover:bg-black hover:text-white rounded-lg shadow-2xl p-3 group">
                                        <div className="m-2 text-justify text-sm">
                                            <img
                                                alt="card img"
                                                className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out group-hover:invert"
                                                src={Consultancy}
                                            />
                                            <div className="font-neuebold">
                                                <h2 className="font-semibold my-4 text-2xl xs:text-xl sm:text-xl md:text-md lg:text-lg xl:text-2xl text-center truncate-2-lines ">
                                                    Consultancy and <br />{" "}
                                                    Optimization
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="MachineLearningService">
                    <div
                        className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
                        data-aos="zoom-in"
                    >
                        <div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                            <div
                                className="flex lg:justify-start w-full lg:w-1/3"
                                data-aos="fade-up"
                                data-aos-delay="700"
                            >
                                <img
                                    alt="card img"
                                    className="rounded-t float-left duration-1000 w-full"
                                    src={MachineLearningIcon}
                                />
                            </div>

                            <div
                                className="lg:w-1/2 flex flex-col justify-center"
                                data-aos="zoom-in"
                                data-aos-delay="200"
                            >
                                <div className="font-neuebold">
                                    <h1 className="mb-5 md:text-5xl text-3xl font-neuebold">
                                        Machine Learing
                                    </h1>
                                </div>
                                <div className="font-neueregular">
                                    <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                                        is an essential component of modern
                                        software development and data science,
                                        and can help your organization stay
                                        ahead of the curve in terms of
                                        innovation and efficiency. Contact us
                                        today to learn more about how our
                                        machine learning services can benefit
                                        your organization. <br />
                                    </div>
                                    <div className="ml:text-4xl md:text-2xl text-3xl  text-xl font-neueregular tracking-tight mb-5 text-gray-500">
                                        <h1>Achievements</h1>
                                        Partnership in neural network model
                                        enhancement with OMNet++.
                                        <br />
                                        We could reach over 10x speed
                                        improvements. <br />
                                        We also presented our result on their
                                        conference at OMNeT++ 2022 Summit.
                                        <br />
                                        <br />
                                        <div className="flex">
                                            <div className="flex flex-col w-1/2 justify-center">
                                                Summit website:  <br />
                                                <Link className="text-black hover:text-gray-500"></Link>
                                                <a
                                                    className="text-black hover:text-gray-500"
                                                    href="https://summit.omnetpp.org/2022/index.html"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Link to summit website
                                                </a>
                                                <br />
                                                Presentation slides:
                                                <br />
                                                <a
                                                    className="text-black hover:text-gray-500"
                                                    href="https://docs.google.com/presentation/d/16strtgl22lZ4h5NvLQ1CsVDVP6sf2GAEW_FWtqnhfpM/edit"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Link to slides
                                                </a>
                                                <br />
                                                Presentation video:
                                                <br />
                                                <a
                                                    className="text-black hover:text-gray-500"
                                                    href="https://www.youtube.com/watch?v=s60bMOL-QlM"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Link to video
                                                </a>
                                                <br />
                                            </div>

                                            <div
                                                className="flex lg:justify-start w-full"
                                                data-aos="fade-up"
                                                data-aos-delay="700"
                                            >
                                                <img
                                                    alt="card img"
                                                    className="rounded-t float-left duration-1000 w-full lg:w-1/2"
                                                    src={swissarrowImg}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                    <Link to="/contact" className="linkbutton">
                                        Contact us
                                        <svg
                                            className="w-4 h-4 ml-1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <hr className="border-black border-t-4 w-full my-8" />
                    </div>
                </section>

                <section id="ComputerVisionService">
                    <div
                        className="m-auto overflow-hidden  py-10  mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
                        data-aos="zoom-in"
                    >
                        <div className="flex flex-col lg:flex-row mt-16 justify-between text-center lg:text-left">
                            <div
                                className="lg:w-1/2 flex flex-col justify-center"
                                data-aos="zoom-in"
                                data-aos-delay="200"
                            >
                                <div className="font-neuebold">
                                    <h2 className="  mb-5 md:text-5xl text-3xl font-bold">
                                        {/* We build digital solutions to help businesses scale */}
                                        Computer Vision
                                    </h2>
                                </div>
                                <div className="font-neueregular">
                                    <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                                        is an essential component of many modern
                                        applications, from retail and healthcare
                                        to manufacturing and logistics. Our
                                        computer vision services can help you
                                        stay ahead of the curve in terms of
                                        innovation and efficiency, enabling you
                                        to achieve your business goals and
                                        objectives more effectively.
                                        <br />
                                        <br />
                                        <h1>Achievements</h1>
                                        Object detection, image processing and
                                        segmentation tasks? <br />
                                        
                                        You can count
                                        on our expertise.
                                        <br /> We are working with companies to
                                        optimize and automatize their workflows
                                        with using the lastest and most suitable
                                        computer vision techniques from
                                        classical methods to the SoTA.
                                    </div>
                                </div>

                                <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                    <Link to="/contact" className="linkbutton">
                                        Contact Us
                                        <svg
                                            className="w-4 h-4 ml-1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </Link>
                                    {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Get Started
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link> */}
                                    {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Learn More
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                                </a> */}
                                </div>
                            </div>
                            <div
                                className="flex lg:justify-end w-full lg:w-1/3"
                                data-aos="fade-up"
                                data-aos-delay="700"
                            >
                                <img
                                    alt="card img"
                                    className="rounded-t float-right duration-1000 w-full"
                                    src={CompVisionIcon}
                                />
                            </div>
                        </div>
                        <hr className="border-black border-t-4 w-full my-8" />
                    </div>
                </section>

                <section id="SWADService">
                    <div
                        className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
                        data-aos="zoom-in"
                    >
                        <div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                            <div
                                className="flex lg:justify-start w-full lg:w-1/3"
                                data-aos="fade-up"
                                data-aos-delay="700"
                            >
                                <img
                                    alt="card img"
                                    className="rounded-t float-left duration-1000 w-full"
                                    src={SWADesign}
                                />
                            </div>

                            <div
                                className="lg:w-1/2 flex flex-col justify-center"
                                data-aos="zoom-in"
                                data-aos-delay="200"
                            >
                                <div className="font-neuebold">
                                    <h1 className="mb-5 md:text-5xl text-3xl font-bold">
                                        Software ural Design
                                    </h1>
                                </div>
                                <div className="font-neueregular">
                                    <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                                        Our team has many years of expertise in
                                        software architectural design.
                                        <br /> Dreaming software is only a tiny
                                        part of the job. If you want your
                                        software to run 24/7, to be optimized,
                                        get it standardized, you are in good
                                        hands.
                                        <br /> Leave the tedious job for us if
                                        you wish to have rock-solid software!
                                    </div>
                                </div>
                                <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                    <Link to="/contact" className="linkbutton">
                                        Contact us
                                        <svg
                                            className="w-4 h-4 ml-1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <hr className="border-black border-t-4 w-full my-8" />
                    </div>
                </section>

                <section id="ConsultancyandOptimizationService">
                    <div
                        className="m-auto overflow-hidden  py-10  mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6"
                        data-aos="zoom-in"
                    >
                        <div className="flex flex-col lg:flex-row mt-16 justify-between text-center lg:text-left">
                            <div
                                className="lg:w-1/2 flex flex-col justify-center"
                                data-aos="zoom-in"
                                data-aos-delay="200"
                            >
                                <div className="font-neuebold">
                                    <h2 className="  mb-5 md:text-5xl text-3xl font-bold">
                                        {/* We build digital solutions to help businesses scale */}
                                        Consultancy and Optimization
                                    </h2>
                                </div>
                                <div className="font-neueregular">
                                    <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                                        Effective communication is crucial for
                                        projects to succeed. <br />
                                        Through milestones, we collaborate with
                                        clients to refine solutions for their
                                        challenges. Share your project details,
                                        and let's elevate it together.
                                    </div>
                                </div>

                                <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                    <Link to="/contact" className="linkbutton">
                                        Contact Us
                                        <svg
                                            className="w-4 h-4 ml-1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </Link>
                                    {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Get Started
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link> */}
                                    {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Learn More
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                                </a> */}
                                </div>
                            </div>
                            <div
                                className="flex lg:justify-end w-full lg:w-1/3"
                                data-aos="fade-up"
                                data-aos-delay="700"
                            >
                                <img
                                    alt="card img"
                                    className="rounded-t float-right duration-1000 w-3/4"
                                    src={Consultancy}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default Services;
