import React, { useState, useEffect } from 'react';
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';
import MachineLearningIcon from '../images/mleaning.svg';
import CompVisionIcon from '../images/compvision.svg';
import SWADesign from '../images/swarchdesgin.svg';
import Consultancy from '../images/consultancy.svg';
import whyworkwithusImg from '../images/whyworkwithus.png';
import {Link, useNavigate, useLocation} from 'react-router-dom';

const Services = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [anchor, setAnchor] = useState(null);
    const [yoffset, setYoffset] = useState(null);

    // Scroll to the anchor after navigation
    useEffect(() => {
        if (anchor) {
            const element = document.getElementById(anchor);
            if (element) {
                const offsetTop = element.getBoundingClientRect().top + window.scrollY-yoffset;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
                setAnchor(null); // Reset the anchor state
                setYoffset(null); // Reset the anchor state
            }
        }
    }, [anchor, location, yoffset]); // React to changes in anchor and location

    const handleClick = (anchorId, yoffset) => {
        setAnchor(anchorId);
        setYoffset(yoffset);
        navigate(`/services#${anchorId}`);
    };

    return (
        <div id="services" className="bg-gray-100 py-12" >

            <section id ="WhyWorkWithUs">
                <div className="m-auto overflow-hidden  py-10  mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">

                    <div className="flex flex-col lg:flex-row mt-16 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            
                        <div className="font-neuebold" >
                            <h2 className="  mb-5 md:text-5xl text-3xl font-bold">
                                {/* We build digital solutions to help businesses scale */}
                                Why work with us?

                            </h2>
                        </div>
                        <div className="font-neueregular">
                        <p className='my-3 text-xl text-gray-600 font-neueregular'>
                                <h1 className='text-xl text-black font-neuebold'>Diversified knowledge</h1>
                                Rich experience from academic, corporate 
                                and entrepreneurial backgrounds.

                                <br/><br/>

                                <h1 className='text-xl text-black font-neuebold'>Clear and professional communication</h1>                                
                                All of our clients were satisfied with
                                how we handled the collaborations.

                                <br/><br/>

                                <h1 className='text-xl text-black font-neuebold'>Robust project management</h1> 
                                Milestone/Checkpoint based payment system. <br/>
                                Trackable, plannable, and exact project management.

                                <br/><br/>

                                </p>
                        </div>
                        
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                <Link to="/contact" className="linkbutton">
                                    Contact Us
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link>
                                {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Get Started
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link> */}
                                {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Learn More
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                                </a> */}
                            </div>
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
                            <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={whyworkwithusImg} />
                        </div>
                    </div>
                    <hr className="border-black border-t-4 w-full my-8" />
                </div>
            </section>


            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-black uppercase font-neuebold">services</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-black'></div>
                        </div>
                    </div>

                   
                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5 h-full">
                            

                            <Link to="/services#MachineLearningService">
                                <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-black-700 hover:bg-black hover:text-white rounded-lg shadow-2xl p-3 group h-full flex flex-col justify-between">
                                    <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out group-hover:invert" src={MachineLearningIcon} />
                                        <div className="font-neuebold">
                                            <h2 className="font-semibold my-4 text-2xl xs:text-xl sm:text-xl md:text-md lg:text-lg xl:text-2xl text-center truncate-2-lines">Machine <br/> Learning</h2>
                                        </div>
                                        <p className="font-neueregular">
                                        is an essential component of modern software development and data science, and can help your organization stay ahead of the curve in terms of innovation and efficiency. Contact us today to learn more about how our machine learning services can benefit your organization.<br/><br/>
                                        </p>
                                    </div>
                                </div>
                            </Link>

                            <Link to="/services#ComputerVisionService">
                                <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-black-700 hover:bg-black hover:text-white rounded-lg shadow-2xl p-3 group h-full flex flex-col justify-between">
                                    <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out group-hover:invert" src={CompVisionIcon} />
                                        <div className="font-neuebold">
                                            <h2 className="font-semibold my-4 text-2xl xs:text-xl sm:text-xl md:text-md lg:text-lg xl:text-2xl text-center truncate-2-lines">Computer <br/> Vision</h2>
                                        </div>
                                        <p className="font-neueregular">
                                        is an essential component of many modern applications, from retail and healthcare to manufacturing and logistics. Our computer vision services can help you stay ahead of the curve in terms of innovation and efficiency, enabling you to achieve your business goals and objectives more effectively.
                                        </p>
                                    </div>
                                </div>
                            </Link>


                            <Link to="/services#SWADService">
                                <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-black-700 hover:bg-black hover:text-white rounded-lg shadow-2xl p-3 group h-full flex flex-col justify-between">
                                    <div className="m-2 text-justify text-sm">
                                        <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out group-hover:invert" src={SWADesign } />
                                        <div className="font-neuebold">
                                            <h2 className="font-semibold my-4 text-2xl xs:text-xl sm:text-xl md:text-md lg:text-lg xl:text-2xl text-center truncate-2-lines">Software Architectural <br/> Design</h2>
                                        </div>
                                        <p className="font-neueregular">
                                        Our team has many years of expertise in software architectural design. 
                                        Dreaming software is only a tiny part of the job. 
                                        If you want your software to run 24/7, to be optimized, get it standardized, you are in good hands.  
                                        Leave the tedious job for us if you wish to have rock-solid software!<br/><br/>
                                        </p>
                                    </div>
                                </div>
                            </Link>


                            <Link to="/services#ConsultancyandOptimizationService">
                                <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-black-700 hover:bg-black hover:text-white rounded-lg shadow-2xl p-3 group h-full flex flex-col justify-between">
                                    <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out group-hover:invert" src={Consultancy} />
                                        <div className="font-neuebold">
                                            <h2 className="font-semibold my-4 text-2xl xs:text-xl sm:text-xl md:text-md lg:text-lg xl:text-2xl text-center truncate-2-lines">Consultancy and <br/> Optimization</h2>
                                        </div>
                                        <p className="font-neueregular">
                                            
                                        Effective communication is crucial for projects to succeed. <br/>Through milestones, we collaborate with clients to refine solutions for their challenges. Share your project details, and let's elevate it together.<br/><br/><br/>

                                        
                                        </p>
                                    </div>
                                </div>          
                            </Link>          
                        </div>
                    </div>
            </section>


        </div>
    )
}

export default Services;
