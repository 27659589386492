import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        }
    }, [location.pathname, location.hash]); // Watch both pathname and hash
  
    return <>
        {props.children}
    </>;
};

export default ScrollToTop;
