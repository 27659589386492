const MainBG = () => {
    return (
        <div className="h-screen
                        bg-cover
                        bg-no-repeat
                        bg-top
                        bg-center
                        bg-[url(./images/ChimeraMainBG-sm.svg)]
                        md:bg-[url(./images/ChimeraMainBG-md.svg)]
                        lg:bg-[url(./images/ChimeraMainBG.svg)]
                        sm:bg-[url(./images/ChimeraMainBG-sm.svg)]
                        xs:bg-[url(./images/ChimeraMainBG-sm.svg)]
                        xxs:bg-[url(./images/ChimeraMainBG-sm.svg)]" />
    )
}
export default MainBG;
