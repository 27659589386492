import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";


const NavLinks = () => {
    return (
        <>
            <div className="font-neuebold flex lg:flex-row md:flex-row sm:flex-row xs:flex-col ">
                <HashLink
                    className="px-16 md:text-md lg:text-xl sm:text-xs font-extrabold text-black-500 hover:text-gray-500 flex items-center"
                    smooth
                    to="/#about"
                >
                    About
                </HashLink>

                <HashLink
                    className="px-16 md:text-md lg:text-xl sm:text-xs  font-extrabold text-black-500 hover:text-gray-500 flex items-center"
                    smooth
                    to="/services"
                >
                    Services
                </HashLink>
                <HashLink
                    className="px-16 md:text-md lg:text-xl sm:text-xs font-extrabold text-black-500 hover:text-gray-500 flex items-center"
                    to="/team"
                >
                    Team
                </HashLink>
                <Link
                    className="text-black md:text-md lg:text-xl sm:text-xs  hover:bg-[#9e7608] inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                    smooth
                    to="/contact"
                >
                    Contact
                </Link>
            </div>
        </>
    );
};

export default NavLinks;
